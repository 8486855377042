import { useContext } from 'react'
import { capitalizeEveryWord, capitalizeFirstLetter } from '../utils'
import { AnalyticsContext, bufferClient } from '../services/rudderstack'

export const NAVIGATION_EVENTS = {
  DWELL_TIME: 'Dwell Time',
}

export const COOKIE_CONSENT_EVENTS = {
  COOKIE_CONSENT_ACCEPTED: 'Cookie Consent Accepted',
  COOKIE_CONSENT_DECLINED: 'Cookie Consent Declined',
}

export const COURSE_EVENTS = {
  EVENT_REGISTRATION_SUCCESS: 'Event Signed Up',
  WAITLIST_SIGNUP_SUCCESS: 'Course Waitlist Signed Up',
  ADDITIONAL_INFO_SUCCESS: 'Additional Info Filled In',
  OPENED_PREVIEW: 'Course Opened Preview',
  SCHEDULE_ITEM_SELECTED: 'Course Schedule Item Selected',
}

export const EVENTS = {
  PAGE_VIEW: 'Page Viewed',
  FORM_VIEW: 'Form Viewed',
  SHARE_EVENT_DIALOG_OPENED: 'Share Event Dialog Opened',
}

export const ADDITIONAL_PROPERTIES = {
  PATH: 'path',
  DURATION: 'Duration',
  SELECTED_ITEM: 'Item',
  FORM_NAME: 'Form Name',
  TYPE: 'Type',
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const client = useContext(AnalyticsContext)

  return client || bufferClient
}

export const getPageCategoryAndNameFromPath = (path) => {
  const pathParts = path.split('/').slice(1)

  return [
    capitalizeFirstLetter(pathParts[0]),
    capitalizeEveryWord(pathParts.join(' ')),
  ]
}
